body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*.MuiDrawer-root .MuiPaper-root {*/
  /*height: 100% !important;*/
  /*min-height: 100vh !important;*/
/*}*/
.k-grid  .k-grid-header  .k-header  .k-link {
  height: auto;
}

.k-grid  .k-grid-header  .k-header {
  white-space: normal;
}

.width15 {
  width: 15% !important;
}
.width5 {
  width: 5% !important;
}
.width10 {
  width: 10% !important;
}
.width17 {
  width: 17% !important;
}
.width20 {
  width: 20% !important;
}

@media all {
  .page-break  { display: none; }
}

@media print {
  .page-break  { display: block; page-break-before: always; }
}

@media print {
  html {zoom: 75%;}
  footer {page-break-after: always;}
  div.break{
    page-break-inside: avoid;
  }
  .all-boarder .MuiTableCell-root {
    border: 1px solid black !important;
    /*font-size: larger !important;*/

  }

  .MuiTableCell-footer {
    color: black !important;
  }
  .MuiTypography-body2 {
    font-size: large !important;
  }
  .MuiTableCell-body {
    color: black !important;
  }

  .fit-all {
    zoom: 85%;
  }

  @page
  {
    size: auto;   /* auto is the initial value */
    margin-bottom: 20px;  /* this affects the margin in the printer settings */

  }
  .no-print, .no-print *
  {
    display: none !important;
  }
  .payroll-sheet {
    max-height: none !important;
    font-size: larger !important;
  }
}
/*.MuiDrawer-paperAnchorLeft{*/
  /*margin-top: 0!important;*/
/*}*/

a {
  color: #ffa038;
  text-underline-mode: none;
  text-decoration: none;
}
.payroll-sheet {
  max-height: 600px;
  /*font-size: large !important;*/
}
 .k-header {
  padding: 3px !important;
}
.k-grid td {
  text-align: end !important;
}
.k-grid ._fullName {
  text-align: start !important;
}

.k-grid .fullName {
  text-align: start !important;
}
.k-grid .k-detail-cell {
   text-align: start !important;
 }
#notification {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 200px;
  padding: 20px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.hidden {
  display: none;
}

.k-grid th, .k-grid td {
  border-left-color: #c6c6c6 !important;
}
.k-grid-columnmenu-popup {
  width: 400px !important;
}

.k-animation-container {
  z-index: 10003 !important;
}

.k-calendar-container {
  z-index: 10001 !important; /* Ensure this is higher than the dialog's z-index */
}

thead {display: table-header-group;}

